.am-sidebar {
  ul.ant-menu-light {
    margin-top: 10px;
    background: #2e363e;
    border-right: none !important;
    li {
      cursor: pointer;
      padding: 0px !important;
      .am-menu-item-full {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // width: 80%;
        border-radius: 5px;
        height: 60px;
        line-height: 60px;
        color: #9e9e9e;
      }
      .am-menu-item-full:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #fff !important;
        svg {
          color: #fff !important;
        };
      }
      .am-menu-item-full:active {
        color: #fff !important;
        svg {
          color: #fff !important;
        };
      }
      .am-menu-item-full.selected {
        color: #fff !important;
        svg {
          color: #fff !important;
        };
      }
    }
    li svg::after {
      display: none !important;
    }
    .ant-menu-item {
      height: 60px;
      line-height: 60px;
    }
    .ant-menu-item:active {
      background: transparent !important;
      color: #fff !important;
    }
    .ant-menu-item:hover {
      color: #fff !important;
    }
  }
}
