#login-wrapper {
  padding: 10px;
  margin-top: 50px !important;
  .login-card {
    width: 500px;
    max-width: 100%;
  }
  .lobo-text {
    color: #4c4c4cd9;
    font-size: 64px;
  }
}

@media screen and (max-width: 480px) {
  #login-wrapper {
    .lobo-text {
      font-size: 54px;
    }
  }
}
