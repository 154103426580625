.ant-btn-primary,
.ant-btn-primary:active,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--primary-color);
}
.ant-tabs-ink-bar {
  background-color: var(--primary-color);
}
.ant-switch-checked {
  background-color: var(--primary-color);
}

.ant-select-selection--single:active,
.ant-select-selection--single:hover,
.ant-select-selection--single:focus,
.ant-input:active,
.ant-input:hover,
.ant-input:focus,
.ant-time-picker-input:active,
.ant-time-picker-input:hover,
.ant-time-picker-input:focus {
  border-color: var(--primary-color) !important;
}
.ant-select-selection--single:focus,
.ant-time-picker-input:focus,
.ant-input:focus {
  box-shadow: 0 0 0 2px var(--primary-shadow-color);
}
.ant-btn:active:not(.ant-btn-danger),
.ant-btn:hover:not(.ant-btn-danger),
.ant-btn:focus:not(.ant-btn-danger) {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
  color: #fff !important;
}

.ant-calendar-date:hover,
.ant-select-dropdown-menu-item:hover,
.ant-select-dropdown-menu-item-active,
.ant-time-picker-panel-select li:hover {
  background: var(--primary-hover);
}

.ant-calendar-decade-panel-decade:hover,
.ant-calendar-year-panel-year:hover {
  background: var(--primary-hover);
}
.ant-calendar-selected-date {
  .ant-calendar-date {
    background-color: var(--primary-color);
  }
}
.ant-calendar-today {
  .ant-calendar-date {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
}
.ant-calendar-selected-date.ant-calendar-today {
  .ant-calendar-date {
    color: var(--primary-color);
    border-color: var(--primary-color);
    background-color: #d1e9ff;
  }
}

.ant-calendar-decade-panel-selected-cell {
  .ant-calendar-decade-panel-decade,
  .ant-calendar-decade-panel-decade:hover {
    background-color: var(--primary-color);
    color: #fff !important;
  }
}
.ant-calendar-year-panel-selected-cell {
  .ant-calendar-year-panel-year,
  .ant-calendar-year-panel-year:hover {
    background-color: var(--primary-color);
    color: #fff !important;
  }
}

#am-calendar .fc-event {
  background-color: var(--primary-hover);
  border: 1px dashed var(--primary-color);
}

.ant-modal-header {
  background: var(--primary-hover);
}
