$am-white: #fff;
$am-light: #eee;
$am-header-simple: #e4ebf1;

@font-face {
  font-family: Rancho;
  src: url(./assets/fonts/Rancho-Regular.ttf);
}
.Rancho {
  font-family: Rancho !important;
}

.color-white {
  color: $am-white;
}

.header-simple-bg {
  background: $am-header-simple;
}
label {
  margin-bottom: 0px;
}
.c-pointer {
  cursor: pointer;
}
.layout-container {
  height: 100%;
}
.bill-modal {
  .ant-modal-content {
    border-radius: 0px !important;
  }
}
.no-interner-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}
.stepper-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-row {
  cursor: pointer;
}
.service-row.selected {
  background-color: #e6f7ff;
}

.elevation1 {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.step-component {
  margin-top: 2rem
}
.bookingFormCard .ant-card-body {
  height: 100%;
}
@media screen and (max-width: 992px) {
  .step-component {
    margin-top: 0rem;
  }
  
}
// @media screen and (max-width: 768px) {
//   .step-component {
//     margin-top: 0rem
//   }
  
// }
// @media screen and (max-width: 576px) {
//   .step-component {
//     margin-top: 0rem
//   }
  
// }