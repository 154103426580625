@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 600px) {
  .booking-wrapper {
    margin-top: 15px !important;
    .ant-steps-dot .ant-steps-item-content{
        width: 85% !important;
        .ant-tag{
            display: block;
            // width: 150px;
            text-align: center;
        }
    }
    .header {
      flex-direction: row;
      align-items: center !important;
      // & > div:last-child {
      //   margin-top: 10px;
      // }
    }
    .scheduler-btn,
    .customer-btn,
    .confirmation-btn {
      position: static !important;
      float: right;
      margin-top: 40px;
    }
    .customer-form-wrapper {
      width: 100% !important;
    }
    .confirmation-left {
      border-right: none !important;
    }
    .otp-input-wrapper{
        flex-direction: row !important;
        align-items: center !important;
        input,
        button{
            margin-top: 5px;
        }
    }
    .mobile-verify-btn-div button {
      width: 100%;
    }
  }
}
