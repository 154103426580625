.merchant-basic-details-form-wrapper {
  width: 70%;
}

@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
  .merchant-basic-details-form-wrapper {
    width: 100%;
  }
}
