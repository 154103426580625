#appointment-queue-wrapper {
  height: 100%;
  .staff-list {
    width: 25%;
    background: #f1f5f7;
    height: 100%;
    overflow: auto;
    .list {
      margin-top: 20px;
      .staff-item {
        border: none !important;
        border-radius: 8px;
      }
      .staff-item:hover,
      .staff-item.active {
        background: rgba(0, 0, 0, 0.1);
        .staff-delete-btn {
          visibility: visible;
        }
      }
      .staff-name {
        font-size: 16px;
        color: #4d4c4c;
      }
      .staff-delete-btn {
        visibility: hidden;
      }
    }
  }
  .staff-appointments {
    width: 75%;
  }
  .appointment-list {
    .ant-card-body {
      padding: 0;
      max-height: 100%;
      overflow: auto;
      .ant-list-split .ant-list-item:last-child {
        border-bottom: 1px solid #e8e8e8 !important;
      }
    }
  }
}

@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
  #service-settings-wrapper {
    .staff-list {
      display: none;
    }
    .staff-appointments {
      width: 100%;
      padding: 5px !important;
    }
  }
}
