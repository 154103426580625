#am-tab-wrapper {
  .menu-wrapper {
    .menu-item {
      height: 100px;
      width: 100px;
      border: 1px solid #eee;
      margin-right: 10px;
      margin-bottom: 10px;
      .am-menu-item-title {
        font-size: 16px;
        color: #ddd;
      }
    }
    .menu-item.active,
    .menu-item:hover {
      border: 1px solid var(--primary-color);
      margin-right: 10px;
      svg,
      .am-menu-item-title {
        color: var(--primary-color);
      }
    }
  }
  .menu-content {
    min-height: 79vh;
  }
}

@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
  #am-tab-wrapper {
    .menu-wrapper {
      .menu-item {
        height: 70px;
        width: 70px;
        svg {
          font-size: 25px
        }
        .am-menu-item-title {
          font-size: 14px;
        }
      }
    }
    .menu-content {
      min-height: 79vh;
      overflow-x: auto;
    }
  }
}
