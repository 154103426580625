#staff-form-wrapper{
    .basic-details-form-wrapper{
        width: 80%;
    }
}


@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
    #staff-form-wrapper{
        .basic-details-form-wrapper{
            width: 100%;
        }
    }
}