.am-navbar {
  background: var(--navbar-gradient-fallback) !important;
  background: var(--navbar-gradient) !important;
  .sidebar-trigger {
    // display: none;
  }
  .user-avatar-name:hover,
  .user-avatar-name:active{
    background: rgba(255,255,255,0.1);
  }
}

@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
  .am-navbar {
    .sidebar-trigger {
      // display: block;
    }
  }
}
@media screen and (max-width: 480px) {
}
