#admin-customers-wrapper {
    height: 100%;
    .customer-list {
      width: 25%;
      background: #f1f5f7;
      height: 100%;
      overflow: auto;
      .list {
        margin-top: 20px;
        .customer-item {
          border: none !important;
          border-radius: 8px;
        }
        .customer-item:hover,
        .customer-item.active {
          background: rgba(0, 0, 0, 0.1);
          .customer-delete-btn {
            visibility: visible;
          }
        }
        .customer-name {
          font-size: 16px;
          color: #4d4c4c;
        }
        .customer-delete-btn {
          visibility: hidden;
        }
      }
    }
    .customer-details {
      width: 75%;
    }
  }
  
  @media screen and (max-width: 990px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
    #service-settings-wrapper {
      .customer-list {
        display: none;
      }
      .customer-details {
        width: 100%;
        padding: 5px !important;
      }
    }
  }
  