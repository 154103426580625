.anticon {
  vertical-align: 1px !important;
}
.ant-form-item {
  margin-bottom: 10px !important;
}
.ant-modal-content {
  border-radius: 20px;
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }
}
.ant-form-vertical {
  .ant-form-item-label {
    padding: 0px;
  }
}
.ant-layout {
  height: 100%;
  background-color: white;
}

h4 {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0px;
    padding: 12px 10px;
  }
  .ant-tabs .ant-tabs-top-content,
  .ant-tabs .ant-tabs-bottom-content {
    overflow-x: auto;
  }
  .ant-drawer-body {
    padding: 0px;
  }
}
