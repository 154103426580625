.am-sidebar {
  ul.ant-menu-light {
    margin-top: 10px;
    background: #2e363e;
    border-right: none !important;
    li {
      cursor: pointer;
      padding: 0px !important;
      .am-menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: auto !important;
        border-radius: 5px;
        height: 60px;
        line-height: 60px;
      }
      .am-menu-item:hover {
        background: rgba(255, 255, 255, 0.1);
        svg {
          color: #fff !important;
        }
      }
    }
    li svg::after {
      display: none !important;
    }
    .ant-menu-item {
      height: 60px;
      line-height: 60px;
    }
    .ant-menu-item:active {
      background: transparent !important;
    }
  }
}
