#staff-settings-wrapper {
  // display: table;
  // width: 100%;
  min-height: 79vh;
  .staff-list {
    width: 25%;
    background: #f1f5f7;
    // height: 100%;
    overflow: auto;
    .list {
      margin-top: 20px;
      .staff-item {
        border: none !important;
        border-radius: 8px;
      }
      .staff-item:hover,
      .staff-item.active {
        background: rgba(0, 0, 0, 0.1);
        .staff-delete-btn {
          visibility: visible;
        }
      }
      .staff-name {
        font-size: 15px;
        color: #4d4c4c;
      }
      .staff-delete-btn {
        visibility: hidden;
      }
    }
  }
  .staff-details {
    width: 75%;
  }
}

@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
  #staff-settings-wrapper {
    .staff-list {
      display: none;
    }
    .staff-details {
      width: 100%;
      padding: 5px !important;
    }
  }
}
@media screen and (max-width: 480px) {
}
