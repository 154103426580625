@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/list/main.css";

#am-calendar {
  padding-top: 48.2px;
  .fc-nonbusiness {
    pointer-events: none;
  }
  .fc-event {
    background-color: #c1edeb;
    border: 1px solid #55bfb3;
    // color: #1e765e;
    transition: 0.2s linear;
    padding: 5px;
    // position: relative;
    span.edit-event-span {
      position: absolute;
      top: 2px;
      right: 5px;
      visibility: hidden;
    }
  }
  .fc-event:hover {
    span.edit-event-span {
      visibility: visible;
    }
  }
  .fc-list-view {
    span.edit-event-span {
      display: none;
    }
  }
  .fc-scroller.fc-time-grid-container {
    overflow: hidden auto !important;
    height: auto !important;
  }
}
.am-calendar-toolbar {
  background: #e4ebf1;
  position: fixed;
  z-index: 9;
  width: 95.6%;
}

@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
  .am-calendar-toolbar {
    width: 100%;
    flex-direction: column;
    padding: 20px;
    .left-corner, .right-corner, .center{
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 480px) {
}
